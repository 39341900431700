import { css, html, LitElement, nothing } from 'lit';

import { heading3Styles } from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import '@brightspace-ui/core/components/expand-collapse/expand-collapse-content';
import '@brightspace-ui/core/components/icons/icon';
import '@brightspace-ui/core/components/button/button';
import '@brightspace-ui/core/components/dialog/dialog-confirm';
import '@brightspace-ui/core/components/inputs/input-text';

import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles';

import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles';

import { getDisplayName, inputTemplate, userFormAttributes } from '../helpers/helpers.js';
import { cardStyles } from '../styles/card.js';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';

class UsersList extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      readonly: { type: Boolean, attribute: 'readonly' },
      relayState: { type: String, attribute: 'relay-state' },
      tenantId: { type: String, attribute: 'tenant-id' },
      domain: { type: String, attribute: 'domain' },
      environmentId: { type: String, attribute: 'environment-id' },
      loginType: { type: String, attribute: 'login-type' },
      _filteredUsers: { type: Array },
      users: { type: Array },
      allowableDomains: { type: Array },
      _userToRemove: { type: Object },
      _currentUser: { type: Object },
      _formType: { type: String },
      _loginLink: { type: String },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading3Styles,
      inputLabelStyles,
      selectStyles,
      cardStyles,
      css`
        :host {
          display: block;
        }
        .user-content {
          height: 100%;
          align-self: center;
          display: grid;
          place-items: center;
        }
        .alert {
          color: var(--d2l-color-cinnabar);
        }
        .user-alert {
          color: var(--d2l-color-cinnabar);
          margin: revert;
        }

      `];
  }

  constructor() {
    super();
    this.readonly = false;
    this._filteredUsers = [];
    this._formType = 'add';
    this._currentUser = {};
    this._userToRemove = {};
    this.relayState = '/';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {

    this.users.sort((a, b) => {
      const aval = a.displayName || a.userId;
      const bval = b.displayName || b.userId;
      return aval.localeCompare(bval);
    });
    this._filteredUsers = [...this.users];
  }

  _userListItemTemplate(user) {
    return html`
      <d2l-list-item @click="${this._userClicked(user.userId)}" href="javascript:void(0)" key="${user.userId}" label="${getDisplayName(user)}">
        <profile-image .user="${user}" slot="illustration"></profile-image>
        ${this.alertTemplate(user)}
        <div slot="actions">
          <d2l-button-icon text="Copy login url" @click="${this._copyUrl(user)}" icon="html-editor:table-row-copy"></d2l-button-icon>
          ${user.guid && this.readonly ? html`<d2l-button-icon text="Copy guid" @click="${this._copyGuid(user)}" icon="html-editor:cut"></d2l-button-icon>` : nothing}
          <d2l-button-icon text="Open in new window" @click="${this._userClicked(user.userId, true)}" icon="tier1:new-window"></d2l-button-icon>
          ${this.readonly ? nothing : html`
          <d2l-button-icon text="Edit user" @click="${this._openEditUserDialog(user)}" icon="tier1:edit"></d2l-button-icon>
          <d2l-button-icon text="Remove user" @click="${this._openRemoveUserDialog(user)}" icon="tier1:close-default"></d2l-button-icon>`}

        </div>
      </d2l-list-item>`;
  }

  _addUserDialogTemplate() {
    return html`
      <d2l-dialog id="user-dialog" title-text="Edit user">
        <d2l-form id="userForm" @change="${this._changeValue(this._currentUser)}">
          ${userFormAttributes.map(attr => inputTemplate(attr, this._currentUser, { attrSuffix: 'user', formType: this._formType }))}
        </d2l-form>
        <d2l-button slot="footer" @click="${this._submitUserForm}" primary data-dialog-action="done">Submit</d2l-button>
        <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
      </d2l-dialog>`;
  }

  _removeUserDialogTemplate() {
    return html`
      <d2l-dialog id="remove-user-dialog" title-text="Remove tenant">
        <p>Are you sure you want to remove the user ${this._userToRemove.displayName || this._userToRemove.userId}?</p>
        <d2l-button slot="footer" @click="${this._removeUser}" primary data-dialog-action="yes">Yes</d2l-button>
        <d2l-button slot="footer" data-dialog-action>No</d2l-button>
      </d2l-dialog>`;
  }

  _clearUserForm() {
    this._currentUser = {};
    for (const attr of userFormAttributes) {
      const attribute = this.shadowRoot.getElementById(attr.id);
      if (attribute) attribute.value = '';
    }
  }

  _openAddUserDialog() {
    this._formType = 'add';
    this._clearUserForm();
    this.userDialog.opened = true;
  }

  _openRemoveUserDialog(user) {
    return e => {
      e.stopPropagation();
      this._userToRemove = user;
      this.removeUserDialog.opened = true;
    };
  }

  _openEditUserDialog(user) {
    return e => {
      e.stopPropagation();
      this._formType = 'edit';
      this._clearUserForm();
      this._currentUser = user;
      this.userDialog.opened = true;
    };
  }

  _copyUrl(user) {
    return async e => {
      e.stopPropagation();
      this._currentUser = user;
      const loginLink = await this._getLoginLink(user.userId);
      navigator.clipboard.writeText(loginLink);
      this.client.createAlert('success', `Copied ${loginLink} to clipboard`);
    };
  }
  _copyGuid(user) {
    return async e => {
      e.stopPropagation();
      const guid = user.guid;
      navigator.clipboard.writeText(guid);
      this.client.createAlert('success', `Copied ${guid} to clipboard`);
    };
  }

  get userDialog() {
    if (!this._userDialog) this._userDialog = this.shadowRoot.getElementById('user-dialog');
    return this._userDialog;
  }

  get removeUserDialog() {
    if (!this._userDialog) this._userDialog = this.shadowRoot.getElementById('remove-user-dialog');
    return this._userDialog;
  }

  async _removeUser() {
    const ret = await this.client.removeUser(this._userToRemove);
    if (ret) {
      const index = this._filteredUsers.findIndex(user => user.userId === this._userToRemove.userId);
      this._filteredUsers.splice(index, 1);
      this._filteredUsers = [...this._filteredUsers];
      this.update();
    }

  }

  async _submitUserForm() {
    const user = await this.client.upsertUser(this._currentUser);
    if (!user) return;
    if (!this._filteredUsers.some(u => u.userId === this._currentUser.userId)) this._filteredUsers.push(this._currentUser);
    this._currentUser = {};
    this._filteredUsers = [...this._filteredUsers];
  }

  _userClicked(userId, newWindow = false) {
    return async e => {
      e.stopPropagation();
      const href = await this._getLoginLink(userId);
      if (!href) return;

      if (newWindow) {
        window.open(href);
      } else {
        location.href = href;
      }

    };
  }

  _changeValue(base) {
    return e => {
      if (e.target.id) {
        const splitId = e.target.id.split('_')[0];
        base[splitId] = e.target.value;
        this.update();
      }
    };
  }

  async _getLoginLink(userId) {
    if (this.loginType === 'magic-link') {
      try {
        return await this.client.fetchLoginLink(this.environmentId, this.tenantId, userId, this.domain);
      } catch (err) {
        const message = await err.text();
        this.client.createAlert('error', `Invalid email address for tenant: ${message}`);
        return null;
      }
    }
    const relayState = this.relayState || '/';
    const host = this.client.isDev() ? 'http://localhost:3000' : `https://us-east-1.api.${location.hostname}`;
    return `${host}/api/sso/login/${this.environmentId}/${this.tenantId}?username=${userId}&relayState=${relayState}&host=${this.domain}`;
  }

  _filterUsers(e) {
    this._filteredUsers = this.users.filter(user => {
      const compareValue = getDisplayName(user);
      return compareValue.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
    });
  }

  _updateRelayState(e) {
    this.relayState = e.target.value;
  }

  alertTemplate(user) {
    const id = `alert_${user.guid}`;
    if (this.loginType === 'magic-link') {
      let tooltipText;
      const email = user?.thirdPartyUserId?.indexOf('@') > 0 ? user.thirdPartyUserId : user.email;
      if (!email) tooltipText = 'User missing email address';
      else if (!this.allowableDomains.includes(email?.split('@')[1]) && !this.allowableDomains.includes(email)) tooltipText = 'User email not compatible with allowable domains';

      if (tooltipText) {
        return html`<div class="d2l-body-small user-alert"><d2l-icon class="alert" id="${id}" icon="tier1:alert"></d2l-icon> <span>${tooltipText}</span></div>`;
      }
    }
    return nothing;
  }

  render() {
    return html`
      <d2l-input-text value="${this.relayState}" @change="${this._updateRelayState}" placeholder="Relay state" label="Relay state" label-hidden></d2l-input-text>
      <d2l-input-text @keyup="${this._filterUsers}" autofocus placeholder="Search for users" label="Search for users" label-hidden></d2l-input-text>
      <d2l-list>
        <d2l-list-controls slot="controls" slim>
          ${this.readonly ? nothing : html`<d2l-button-subtle text="Add user" icon="tier1:add" @click="${this._openAddUserDialog}"></d2l-button-subtle>`}
        </d2l-list-controls>

        ${this._filteredUsers.map(user => this._userListItemTemplate(user))}
      </d2l-list>
      ${this._addUserDialogTemplate()}
      ${this._removeUserDialogTemplate()}
    `;
  }

}

window.customElements.define('pm-users-list', UsersList);
