/**
 * TODO: Figure out a better way of abstracting out these functions. This helper makes it seem like the component structure isn't quite right.
 */
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator';
import { html } from 'lit';

export const deployFormAttributes = [
  { id: 'branchName', label: 'Branch name', required: true, disabled: () => false },
];

export const tenantFormAttributes = [
  { id: 'loginType', label: 'Login type', required: true, disabled: () => true, type: 'multi-select', attributes: [{ label: 'Magic link', value: 'magic-link' }, { label: 'SAML', value: 'saml' }] },
  { id: 'tenantId', label: 'Tenant ID', required: true, disabled: () => true },
  { id: 'displayName', label: 'Display name', required: true, disabled: () => false },
  { id: 'host', label: 'Sign on URL', required: true, disabled: type => type === 'edit' },
  { id: 'id', label: 'SSO ID', required: true, disabled: () => true },
];
export const userFormAttributes = [
  { id: 'userId', label: 'User ID', required: true, disabled: type => type !== 'add' },
  { id: 'displayName', label: 'Display name', required: false, disabled: () => false },
  { id: 'employeeId', label: 'Employee ID', required: false, disabled: () => false },
  { id: 'firstName', label: 'First name', required: false, disabled: () => false },
  { id: 'lastName', label: 'Last name', required: false, disabled: () => false },
  { id: 'email', label: 'Email', required: false, disabled: () => false },
  { id: 'title', label: 'Title', required: false, disabled: () => false },
  { id: 'department', label: 'Department', required: false, disabled: () => false },
  { id: 'managerName', label: 'Manager name', required: false, disabled: () => false },
  { id: 'managerId', label: 'Manager ID', required: false, disabled: () => false },
  { id: 'win', label: 'WIN', required: false, disabled: () => false },
];

export const inputTemplate = (attr, base, opts = { attrSuffix: 'attr', formType: 'add' }) => {
  const { attrSuffix, formType } = opts;
  const id = `${attr.id}_${attrSuffix}`;
  if (attr.type === 'multi-select') {
    return html`
        <label class="d2l-input-label" for="${name}">${attr.label}</label>
        <select id="${id}" name="${name}" class="d2l-input-select" .value="${base[attr.id]}">
          <option value="">-- Select ${attr.label} --</option>
          ${attr.attributes.map(a => html`
            <option value="${a.value}" .selected=${base[attr.id] === a.value}>${a.label}</option>
          `)}
        </select>
      `;
  }
  return html`<d2l-input-text ?required="${attr.required}" ?disabled="${attr.disabled(formType)}" label="${attr.label}" name="${id}" id="${id}" type="text" value="${base[attr.id]}"></d2l-input-text>`;
};

export const mapify = (array, id = 'id') => {
  const result = {};
  if (!array) return result;
  array.map(item => {
    result[item[id]] = item;
  });
  return result;
};

export const tenantLoginTypeIndicator = type => {
  return type === 'saml' ?
    html`
              <d2l-status-indicator style="height: fit-content" state="default" text="SAML"></d2l-status-indicator>
            ` :
    html`
              <d2l-status-indicator style="height: fit-content" state="none" text="Magic link"></d2l-status-indicator>
            `;

};

export const tenantTypeIndicator = type => {
  return type === 'provider' ?
    html`
              <d2l-status-indicator style="height: fit-content" state="default" text="provider"></d2l-status-indicator>
            ` : type === 'employer' ?
      html`
                <d2l-status-indicator style="height: fit-content" state="none" text="employer"></d2l-status-indicator>
              ` :
      html`
        <d2l-status-indicator style="height: fit-content" state="none" text="admin"></d2l-status-indicator>
      `;

};

const createStatusIndicator = (environmentId, id, state, text, tooltip = '', bold = false) => html`
  <div style="position: relative; display: inline-block;">
    <d2l-status-indicator
      id="${environmentId}-${id}"
      class="status-indicator"
      state="${state}"
      text="${text}"
      ?bold="${bold}"
      aria-describedby="${tooltip ? id : ''}">
    </d2l-status-indicator>
    ${tooltip
    ? html`<d2l-tooltip for="${environmentId}-${id}" position="bottom">${tooltip}</d2l-tooltip>`
    : ''}
  </div>
`;

export const getWaveStatusIndicator = (environmentId, s) => {
  if (!s) {
    return createStatusIndicator(environmentId, 'wave-checking', 'none', 'Checking...');
  }

  if (s.status === 'ok') {
    const needsUpgrade = !s?.tags?.some(t => t === 'OAUTH-KRAKEN');
    if (needsUpgrade) {
      return createStatusIndicator(
        'wave-upgrade',
        'none',
        'Status: Needs Upgrade',
        'Newer versions of Kraken get Wave info through OAuth. Upgrade to the newest version to make Kraken work.'
      );
    }
    return createStatusIndicator(environmentId, 'wave-healthy', 'success', 'Status: Healthy');
  }

  if (s.status === 'error') {
    return createStatusIndicator(environmentId, 'wave-error', 'critical', 'Status: Error', s.message, true);
  }

  return createStatusIndicator(environmentId, 'wave-unknown', 'critical', 'Unknown', s.message);
};

export const getFUSSStatusIndicator = (environmentId, s) => {
  if (!s) {
    return createStatusIndicator(environmentId, 'fuss-checking', 'none', 'Checking...');
  }

  if (s.status === 'ok') {
    return createStatusIndicator(environmentId, 'fuss-healthy', 'success', 'Status: Healthy');
  }

  return createStatusIndicator(environmentId, 'fuss-unhealthy', 'critical', 'Status: Error', s.message, true);
};

export const getDisplayName = user => {
  const firstLast = `${user.firstName || ''} ${user.lastName || ''}`.trim();
  return firstLast || user.name || user.email || user.thirdPartyUserId || user.guid || user.displayName || 'Unknown';
};
