import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/button/button-subtle';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { redirect } from 'd2l-router';

import '../components/environment.js';
import { getWaveStatusIndicator, inputTemplate } from '../helpers/helpers.js';

const environmentFormAttributes = [
  { id: 'id', label: 'Stage name', required: true, disabled: () => false },
  { id: 'displayName', label: 'Display name', required: true, disabled: () => false },
];

class Environments extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      _environments: { type: Array },
      environmentId: { type: String, attribute: 'environment-id' },
      _expandedPanels: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      inputLabelStyles,
      inputStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .environment {
          margin: 10px 0;
        }

        .add-environment-button {
          display: inline-block;
        }

        .environments-header {
          display: inline-block;
        }

        .header {
          align-items: center;
          display: flex;
        }

        .env-header {
          font-weight: bold;
          padding-top: 10px;
        }

        .deploy-date {
          font-size: 0.8em;
        }

        .spacer {
          flex-grow: 1;
        }

        ::slotted([slot=header]) {
          grid-row: header;
        }

        ::slotted([slot=content]) {
          grid-row: content;
        }

        d2l-collapsible-panel-summary-item {
          display: flex;
          justify-content: space-between;
        }

        #deploy-environment-dialog {
          height: 300px;
        }

        #tenantForm {
          padding-bottom: 300px;
        }

        .branch-input {
          width: 500px;
        }
    `];
  }

  constructor() {
    super();
    this._currentEnvironment = { tenants: [] };
    this._environments = [];

    this._branchName = 'main';
    this._oldBranchName = 'main';
    this._branchNames = [];
    this._expandedPanels = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {
    this._environments = await this.client.fetchEnvironments();
    this._environments.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this._branchNames = [];
    const autocomplete = this.shadowRoot.getElementById('branch-select');
    autocomplete.data = this._branchNames.map(branch => {return { value: branch };});
    autocomplete.filterFn = (value, filter) => value.toLowerCase().includes(filter.toLowerCase());
  }

  get deployDialog() {
    if (!this._deployDialog) this._deployDialog = this.shadowRoot.getElementById('deploy-environment-dialog');
    return this._deployDialog;
  }

  openDeployDialog(id) {
    return () => {
      this.deployDialog.opened = true;
      this.environmentId = id;
    };
  }

  closeDeployDialog() {
    this.deployDialog.opened = false;
  }

  branchInputBlur(e) {
    if (this._branchNames.includes(e.target.value)) {
      this._branchName = e.target.value;
      this._oldBranchName = this._branchName;
    } else {
      this._branchName = this._oldBranchName;
      this.shadowRoot.getElementById('branch-input').value = this._branchName;
    }
  }

  _deployEnvironment() {
    return async() => {
      await this.client.deployEnvironment(this.environmentId, this._branchName);
      redirect(`/deployments/${this.environmentId}`);
      this.closeDeployDialog();
    };
  }

  get _deployDialogTemplate() {
    return html`
      <d2l-dialog id="deploy-environment-dialog" title-text="Deploy environment">
        <d2l-form id="tenantForm">
          <d2l-labs-autocomplete
            id="branch-select"
            show-on-focus
          >
            <input
              id="branch-input"
              class="d2l-input branch-input"
              slot="input"
              type="text"
              .value="${this._branchName}"
              label="Branch"
              @keydown=${this._stopPropagation}
              @blur="${this.branchInputBlur}"
            >
          </d2l-labs-autocomplete>
        </d2l-form>
        <d2l-button slot="footer" @click="${this._deployEnvironment()}" primary data-dialog-action="done">Submit</d2l-button>
        <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
      </d2l-dialog>`;
  }

  _stopPropagation(e) {
    const [HOME, END] = [36, 35];
    const { keyCode } = e;
    if (keyCode === HOME || keyCode === END) {
      e.stopPropagation();
    }
  }

  get environmentDialog() {
    if (!this._environmentDialog) this._environmentDialog = this.shadowRoot.getElementById('show-environment-dialog');
    return this._environmentDialog;
  }

  _openAddEnvironmentDialog() {
    this.environmentDialog.opened = true;
  }

  _changeValue(base) {
    return e => {
      if (e.target.id) {
        const splitId = e.target.id.split('_')[0];
        base[splitId] = e.target.value;
        this.update();
      }
    };
  }

  async _submitEnvironmentForm() {
    const environment = await this.client.createEnvironment(this._currentEnvironment);
    if (!environment) return;
    this._environments = [...this._environments, environment];
  }

  _addEnvironmentDialogTemplate() {
    return html`
      <d2l-dialog id="show-environment-dialog" title-text="Create environment">
        <d2l-form id="environmentForm" @change="${this._changeValue(this._currentEnvironment)}">
          ${environmentFormAttributes.map(attr => inputTemplate(attr, this._currentEnvironment))}
        </d2l-form>
        <d2l-button slot="footer" @click="${this._submitEnvironmentForm}" primary data-dialog-action="done">Submit</d2l-button>
        <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
      </d2l-dialog>`;
  }

  _viewDeployments(id) {
    return () => {
      redirect(`/deployments/${id}`);
    };
  }

  _handleExpand(id) {
    return () => {
      this._expandedPanels = {
        ...this._expandedPanels,
        [id]: true, // Mark the panel as expanded
      };
    };
  }

  _handleCollapse(id) {
    return () => {
      this._expandedPanels = {
        ...this._expandedPanels,
        [id]: false, // Mark the panel as collapsed
      };
    };
  }

  navigateToEnvironment(id) {
    return () => {
      redirect(`/stage/${id}`);
    };
  }

  render() {
    return html`
      <div class="header">
        <h1 class="d2l-heading-1 environments-header">Instances - </h1>
        <d2l-button-subtle
          @click="${this._openAddEnvironmentDialog}"
          icon="tier1:add"
          text="Add environment">
        </d2l-button-subtle>
      </div>
      ${this._environments.map(environment => html`
        <d2l-collapsible-panel
          panel-title=${environment.displayName}
          class="environment"
          @d2l-collapsible-panel-expand="${this._handleExpand(environment.id)}"
          @d2l-collapsible-panel-collapse="${this._handleCollapse(environment.id)}">
          <div slot="header">
            <span class="deploy-date" style="padding-top: 5px;">
              ${getWaveStatusIndicator(environment.id, environment.status)}
            </span>
          </div>
          <div slot="actions">
            <d2l-button-icon
              text="View"
              icon="tier1:search"
              @click="${this.navigateToEnvironment(environment.id)}">
            </d2l-button-icon>
          </div>
          ${this._expandedPanels[environment.id]
    ? html`<pm-environment class="environment" environment-id="${environment.id}"></pm-environment>`
    : ''}
        </d2l-collapsible-panel>
      `)}
      ${this._deployDialogTemplate}
      ${this._addEnvironmentDialogTemplate()}
    `;
  }
}

window.customElements.define('pm-environments', Environments);
