import { html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';

import '../components/environment.js';
import '../components/fuss/fuss-stage-config.js';
import { redirect } from 'd2l-router';

class Stage extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      environmentId: { type: String, attribute: 'environment-id' },
    };
  }

  static get styles() {
    return [
      heading1Styles,
    ];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  _removeEnvironment() {
    return () => {
      this.client.removeEnvironment(this.environmentId);
      this.closeRemoveDialog();
      redirect('/');
    };
  }

  get removeDialog() {
    if (!this._removeDialog) this._removeDialog = this.shadowRoot.getElementById('remove-environment-dialog');
    return this._removeDialog;
  }

  openRemoveDialog() {
    this.removeDialog.opened = true;
  }

  closeRemoveDialog() {
    this.removeDialog.opened = false;
  }

  get _removeDialogTemplate() {
    return html`
      <d2l-dialog id="remove-environment-dialog" title-text="Remove environment">
        <div style="font-size: medium;">
          We don't currenly have automatic teardowns hooked up - please manually teardown your stack before removing it from kraken <br><br>

          <b>Teardown via command line:</b> <br>
          'npx severless remove --stage=stageName'
          <br><br>
          <b>Teardown via AWS GUI:</b> <br>
          In cloudformation, navigate to the stack and click into it. In the top right corner you'll see a 'Delete' button which will trigger off the teardown.
        </div>
        <d2l-button slot="footer" @click="${this._removeEnvironment()}" primary data-dialog-action="done">Serverless Teardown Complete</d2l-button>
        <d2l-button slot="footer" data-dialog-action>Cancel</d2l-button>
      </d2l-dialog>`;
  }

  render() {
    return html`
        <div class="header"><h1 class="d2l-heading-1">${this.environmentId} <d2l-button-subtle text="Remove" icon="tier1:close-default" @click="${this.openRemoveDialog}"></d2l-button-subtle></h1></div>
        <pm-environment class="environment" environment-id="${this.environmentId}"></pm-environment>
        <pm-fuss-stage-config environment-id="${this.environmentId}"></pm-fuss-stage-config>
        ${this._removeDialogTemplate}
      `;
  }

}

window.customElements.define('pm-stage', Stage);
