import { LitElement, html, css, nothing } from 'lit';
import '@brightspace-ui/core/components/button/button-icon.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

class JwtDecoderComponent extends RequesterMixin(LitElement) {
  static properties = {
    jwt: { type: String },
    decodedPayload: { type: Object },
    errorMessage: { type: String },
  };

  static styles = css`
    :host {
      display: block;
    }

    .error {
      color: var(--d2l-color-cinnabar);
    }

    .decoded {
      white-space: pre-wrap;
      word-break: break-word;
      background-color: var(--d2l-color-ferrite);
      color: var(--d2l-color-sylvite);
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .token {
      margin-top: 20px;
    }

    .token-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .copy-button {
      margin-left: 10px;
    }
  `;

  constructor() {
    super();
    this.jwt = '';
    this.decodedPayload = null;
    this.errorMessage = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  updated(changedProperties) {
    if (changedProperties.has('jwt')) {
      this.decodeJwt();
    }
  }

  decodeJwt() {
    this.errorMessage = '';
    this.decodedPayload = null;

    if (!this.jwt) return;

    try {
      const [header, payload, signature] = this.jwt.split('.');
      if (!header || !payload || !signature) {
        throw new Error('Invalid JWT format');
      }

      const decodedPayload = JSON.parse(atob(payload));

      // Convert issued at and expiry times to local timezone
      if (decodedPayload.iat) {
        decodedPayload.iatLocal = new Date(decodedPayload.iat * 1000).toLocaleString();
      }
      if (decodedPayload.exp) {
        decodedPayload.expLocal = new Date(decodedPayload.exp * 1000).toLocaleString();
      }

      this.decodedPayload = decodedPayload;
    } catch (error) {
      this.errorMessage = error.message || 'Failed to decode JWT';
    }
  }

  async copyToClipboard() {
    try {
      await navigator.clipboard.writeText(this.jwt);
      this.client.createAlert('success', 'Copied to clipboard');
    } catch {
      this.client.createAlert('error', 'Error copying to clipboard');
    }
  }

  handleJwtChange(e) {
    this.jwt = e.target.value;
  }

  render() {
    return html`
      <div>
        <div class="token">
          <div class="token-header">
            <h3 class="d2l-body-small">JWT</h3>
            <d2l-button-icon
              class="copy-button"
              icon="tier1:copy"
              text="Copy Token"
              @click="${this.copyToClipboard}"
            ></d2l-button-icon>
          </div>
          <d2l-input-textarea
            id="textarea"
            .value="${this.jwt ?? ''}"
            @input="${this.handleJwtChange}"
            placeholder="Paste your JWT here"
          ></d2l-input-textarea>
        </div>

        ${this.errorMessage ? html`<p class="error d2l-body-small">${this.errorMessage}</p>` : nothing}

        ${this.decodedPayload ? html`
          <div class="token">
            <h3 class="d2l-body-small">Decoded Payload</h3>
            <div class="decoded d2l-body-small">
              ${JSON.stringify(this.decodedPayload, null, 2)}
            </div>
          </div>
        ` : ''}
      </div>
    `;
  }
}

customElements.define('jwt-decoder', JwtDecoderComponent);
