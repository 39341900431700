import { css, html, LitElement, nothing } from 'lit';

import { bodySmallStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { repeat } from 'lit/directives/repeat.js';

import '@brightspace-ui/core/components/tabs/tabs';
import '@brightspace-ui/core/components/tabs/tab-panel';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumb-current-page.js';
import '@brightspace-ui/core/components/breadcrumbs/breadcrumbs.js';

import '../components/fuss/fuss-user-list.js';
import '../components/fuss/fuss-sync-table.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { mapify, tenantTypeIndicator } from '../helpers/helpers.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles';
import { redirect } from 'd2l-router';

class PMFussTenant extends SkeletonMixin(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      relayState: { type: String, attribute: 'relay-state' },
      tenantId: { type: String, attribute: 'tenant-id' },
      environmentId: { type: String, attribute: 'environment-id' },
      tenantUsers: { type: Array },
      _domain: { type: Object },
      _healthStatus: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      selectStyles,
      heading1Styles,
      bodySmallStyles,
      inputLabelStyles,
      css`
        :host {
          display: block;
        }
        .login-type {
          color: var(--d2l-color-ferrite);
        }
        .decoded {
          white-space: pre-wrap;
          word-break: break-word;
          background-color: var(--d2l-color-ferrite);
          color: var(--d2l-color-sylvite);
          padding: 15px;
          border-radius: 5px;
          margin-bottom: 10px;
        }
        .inline-container {
          display: flex;
          align-items: center;
          padding-bottom: 1rem;
        }
        .inline-container > *:not(:last-child) {
          margin-right: 0.5rem;
        }
        .separator {
          margin: 0 0.25rem;
        }
        .tenant-logo {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        .tenant-details {
          margin: 1rem 0;
          padding: 1rem;
          display: table;
        }
        .tenant-detail {
          display: table-row;
          height: 32px;
        }
        .panel {
          padding-bottom: 24px
        }
        .tenant-detail span {
          font-weight: bold;
          display: table-cell;
          padding-right: 1rem;
        }
        .tenant-detail div {
          display: table-cell;
        }
        .logo-container {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        .header-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
        }

        .tenant-logo {
          height: 75px;
          width: auto; /* Maintains aspect ratio */
        }

        .nav-links {
          display: flex;
          gap: 15px; /* Space between links */
        }

        .nav-links a {
          text-decoration: none;
          color: #0073e6; /* Link color */
          font-weight: 500;
        }

        .nav-links a:hover {
          text-decoration: underline;
          color: #0056b3; /* Hover color */
        }

      `,
    ];
  }

  constructor() {
    super();
    this._tenant = {};
    this._newDomain = {};
    this.skeleton = true;
    this._domainsMap = [];
    this.tenantUsers = [];
    this.allUsers = [];
    this.relayState = '/';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  updated(changedProperties) {
    if (changedProperties.has('relayState')) {
      if (this.relayState.startsWith('/')) {
        this.relayState = this.relayState.substring(1);
      }
    }
  }

  async firstUpdated() {
    this.environment = await this.client.fetchEnvironment(this.environmentId);
    if (!this.environment?.fussUrl) {
      // include the current query params in the redirect
      redirect(`/tenants/${this.environmentId}/${this.tenantId}?fuss=no&${window.location.search}`);
      return;
    }
    this.tenantUsers = await this.client.fetchTenantUsers(this.environmentId, this.tenantId);
    this._tenant = await this.client.fetchTenantSSO(this.environmentId, this.tenantId);
    this._domain = (this._tenant.ssoDomains.find(x => x.default) || this._tenant.ssoDomains[0] || {}).id;
    this._domainsMap = mapify(this._tenant.ssoDomains);
    this._existingURLs = this._tenant.ssoDomains.map(domain => domain.domain);
    this.skeleton = false;
    this._healthStatus = await this.client.fetchEnvironmentHealth(this.environmentId);
    this.bypassLoginLinkTenants = this._healthStatus?.waveHealth?.settings?.bypassLoginLinkTenants || [];
  }

  get currentDomain() {
    return this._domainsMap[this._domain] || {};
  }

  _changeDomain(e) {
    this._domain = e.target.value;
  }
  _copyGuid() {
    navigator.clipboard.writeText(this.tenantId);
    this.client.createAlert('success', `Copied ${this.tenantId} to clipboard`);
  }

  _updateRelayState(e) {
    this.relayState = e.target.value;
  }

  usersListTemplate() {
    if (!this._tenant?.ssoDomains) return nothing;
    return html`
      <d2l-breadcrumbs style="max-width: fit-content; padding-top: 20px">
        <d2l-breadcrumb
          text="${this.environmentId}"
          href="/stage/${this.environmentId}">
        </d2l-breadcrumb>
        <d2l-breadcrumb-current-page
          text="${this._tenant.name}">
        </d2l-breadcrumb-current-page>
      </d2l-breadcrumbs>
      ${this.headerTemplate()}
      ${this.tenantInfoTemplate()}
      <div class="inline-container">
        <label for="domain-select" class="d2l-input-label">Url</label>
        <select
          id="domain-select"
          aria-label="Choose a domain:"
          .value=${this._domain}
          @change=${this._changeDomain}
          class="d2l-input-select"
        >
          ${repeat(this._tenant.ssoDomains, sso => sso.id, sso => html`
            <option .selected=${sso.id === this._domain} .value=${sso.id}>
              <span class="login-type">(${sso.loginType})</span> ${sso.domain}
            </option>
          `)}
        </select>
        <span class="separator">/</span>
        <d2l-input-text
          id="relay-state"
          .value="${this.relayState}"
          @change="${this._updateRelayState}"
          label="Path"
          label-hidden
        ></d2l-input-text>
      </div>
      <pm-fuss-users-list
        @wave-user-clicked="${this._waveUserClicked}"
        .users="${this.tenantUsers}"
        .tenant="${this._tenant}"
        .bypassLoginLinkTenants="${this.bypassLoginLinkTenants}"
        relay-state="${this.relayState}"
        tenant-id="${this.tenantId}"
        environment-id="${this.environmentId}"
        login-type="${this.currentDomain.loginType}"
        .allowableDomains="${this.currentDomain.allowableDomains}"
        domain=${this.currentDomain.domain}>
      </pm-fuss-users-list>
    `;
  }

  _waveUserClicked(e) {
    this.relayState = `/settings/user-management/edit?guid=${e.detail.guid}`;
    const element = this.shadowRoot.getElementById('relay-state');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });

      // Focus the element
      element.focus();
    }
  }

  getRootDomain() {
    const hostname = new URL(window.location.href).hostname;
    const parts = hostname.split('.');
    if (parts.length >= 2) {
      return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }
    return hostname;
  }

  headerTemplate() {
    const stripeQuery = encodeURIComponent(`is:customer cus_tenant_${this.environmentId}_${this._tenant.tenantId}`);
    return html`
      <div class="header-container">
        <img src="${this._tenant.imageUrl}" alt="Tenant logo" class="tenant-logo" height="75">
        <nav class="nav-links">
          <a href="https://admin.${this.environmentId}.${this.getRootDomain()}/tenants/${this._tenant.tenantId}/edit">Edit tenant</a>
          <a href="https://dashboard.stripe.com/test/search?query=${stripeQuery}">Stripe</a>
          <a href="/tenants/${this.environmentId}/${this.tenantId}?fuss=no">Kraken Users</a>
        </nav>
      </div>`;
  }

  tenantInfoTemplate() {
    return html`
      <d2l-collapsible-panel panel-title="${this._tenant.name}" class="panel">
        <div slot="actions">
          <d2l-button-icon text="Copy guid" title="Copy guid" @click="${this._copyGuid}" icon="html-editor:cut"></d2l-button-icon>
        </div>
        <div slot="header" style="align-items: center; display: flex; gap: 0.6rem;">
          <span>${tenantTypeIndicator(this._tenant.type)}</span>
          <p class="d2l-body-small"> - ${this.environmentId} - ${this.tenantId}</p>
        </div>
        <div class="d2l-body-small">
          <div class="tenant-details d2l-body-small">
            <div class="tenant-detail"><span>Approval Model</span><div>${this._tenant.approvalModel}</div></div>
            <div class="tenant-detail"><span>Budget Type</span><div class="decoded">${JSON.stringify(this._tenant.budget, null, 2)}</div></div>
            <div class="tenant-detail"><span>Entitlements</span><div class="decoded">${JSON.stringify(this._tenant.entitlements, null, 2)}</div></div>
          </div>
        </div>
      </d2l-collapsible-panel>`;
  }

  render() {
    return html`
      <d2l-tabs>
        <d2l-tab-panel text="Users">${this.usersListTemplate()}</d2l-tab-panel>
      </d2l-tabs>
    `;
  }

}

window.customElements.define('pm-fuss-tenant', PMFussTenant);
