import { css, html, LitElement, nothing } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import { bodySmallStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles';

import '@brightspace-ui/core/components/button/button';
import '@brightspace-ui/core/components/inputs/input-text';

import '../environment.js';
import '../jwt-decoder.js';
import { getFUSSStatusIndicator, getWaveStatusIndicator } from '../../helpers/helpers.js';

class FussStageConfig extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      environmentId: { type: String, attribute: 'environment-id' },
      _environment: { type: Object },
      _healthStatus: { type: Object },
      _systemTokens: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      bodySmallStyles,
      css`
        :host {
          display: block;
          padding: 20px 0;
        }
        d2l-input-text {
          padding: 20px 0;
        }
        .panel {
          padding-top: 20px;
        }
        .panel-content {
          display: grid;
          grid-template-columns: auto 1fr; /* Label and value columns */
          gap: 10px;
          align-items: start;
        }

        .panel-content strong {
          white-space: nowrap; /* Prevent wrapping for labels */
        }

        .panel-content ul {
          margin: 0;
          padding-left: 20px; /* Add padding for the list indentation */
        }
      `,
    ];
  }

  constructor() {
    super();
    this._healthStatus = {};
    this._systemTokens = {};
    this._environment = {};
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
    try {
      this._environment = await this.client.fetchEnvironment(this.environmentId);
    } catch {
      this._environment = { id: this.environmentId };
    }
    this._environment.fussUrl = this._environment?.fussUrl?.replace('https://', '') || '';
    this._healthStatus = await this.client.fetchEnvironmentHealth(this.environmentId);
  }

  async save() {
    const amplitudeProjectId = this._environment.amplitudeProjectId;
    await this.client.updateFussConfig(this.environmentId, { amplitudeProjectId });
  }

  handleFormChange(e) {
    this._environment = {
      ...this._environment,
      [e.target.id]: e.target.value,
    };
  }

  async testConnection() {
    const response = await this.client.testFussConnection(this._environment.fussUrl);
    if (response.success) {
      this.client.createAlert('success', 'Connection successful');
    } else {
      this.client.createAlert('error', 'Connection failed');
    }
  }

  amplitudePanelTemplate() {
    const amplitudeProjectId = this._environment?.amplitudeProjectId || '';
    return html`
      <d2l-collapsible-panel panel-title=Amplitude class="panel">
        <d2l-form @change="${this.handleFormChange}">
          <d2l-input-text id="amplitudeProjectId" label="Amplitude Project ID" .value="${amplitudeProjectId}" required></d2l-input-text>
          <d2l-button type="button" primary @click="${this.save}">Save</d2l-button>
        </d2l-form>
      </d2l-collapsible-panel>
    `;
  }

  async generateWaveToken() {
    const { token } = await this.client.fetchToken(this.environmentId, 'wave');
    this._systemTokens.wave = token;
    this._systemTokens = { ...this._systemTokens };
  }

  async generateFUSSToken() {
    const { token } = await this.client.fetchToken(this.environmentId, 'fuss');
    this._systemTokens.fuss = token;
    this._systemTokens = { ...this._systemTokens };
  }

  fussPanelTemplate() {
    return html`
      <d2l-collapsible-panel panel-title=FUSS class="panel">
        <div slot="header" style="align-items: center; display: flex; gap: 0.6rem;">
          ${getFUSSStatusIndicator(this.environmentId, this._healthStatus.fussHealth)}
          <p class="d2l-body-small"> - https://${this._environment.fussUrl}</p>
        </div>
        ${this.client.isProd() ? nothing : html`<d2l-button @click="${this.generateFUSSToken}">Generate Token</d2l-button>`}
        <jwt-decoder .jwt="${this._systemTokens?.fuss?.access_token}"></jwt-decoder>
      </d2l-collapsible-panel>
    `;
  }

  wavePanelTemplate() {
    return html`
      <d2l-collapsible-panel panel-title=Wave class="panel">
        <div slot="header" style="align-items: center; display: flex; gap: 0.6rem;">
          ${getWaveStatusIndicator(this.environmentId, this._healthStatus.waveHealth)}
        </div>
        ${this.client.isProd() ? nothing : html`<d2l-button @click="${this.generateWaveToken}">Generate Token</d2l-button>`}
        <jwt-decoder .jwt="${this._systemTokens?.wave?.access_token}"></jwt-decoder>
      </d2l-collapsible-panel>
    `;
  }

  settingsPanelTemplate() {
    if (!this._healthStatus?.waveHealth?.settings) return nothing;
    const { settings } = this._healthStatus.waveHealth;

    // Check if bypass is disabled
    const bypassDisabledKeywords = ['none', 'disabled', 'off', undefined, ''];
    const bypassTenants = settings.bypassLoginLinkTenants || [];

    const bypassTenantsDisplay = bypassDisabledKeywords.some(keyword => bypassTenants.includes(keyword))
      ? 'Bypass is disabled'
      : bypassTenants.includes('all')
        ? 'All domains'
        : html`
        <ul>
          ${bypassTenants.map(tenant => html`<li>${tenant.trim()}</li>`)}
        </ul>
      `;

    return html`
    <d2l-collapsible-panel panel-title="Settings" class="panel">
      <div class="panel-content d2l-body-small">
        <strong>Email enabled:</strong>
        <span>${settings?.emailEnabled ? 'Yes' : 'No'}</span>

        <strong>Bypass magic link emails for these tenants:</strong>
        <span>${bypassTenantsDisplay}</span>
      </div>
    </d2l-collapsible-panel>
  `;
  }

  render() {
    return html`
      ${this.wavePanelTemplate()}
      ${this.fussPanelTemplate()}
      ${this.amplitudePanelTemplate()}
      ${this.settingsPanelTemplate()}
      `;
  }
}

window.customElements.define('pm-fuss-stage-config', FussStageConfig);
