import { html } from 'lit';

import { registerRoutes } from 'd2l-router/router.js';

import './pages/environments.js';
import './pages/deployments.js';
import './pages/idp.js';
import './pages/tenant.js';
import './pages/fuss-tenant.js';
import './pages/stage.js';
import './pages/home.js';

registerRoutes(
  [
    {
      pattern: '*',
      view: () => html`<h1>Not Found</h1>`,
    },
    {
      pattern: 'idp-info',
      view: () => html`
        <h1 class="d2l-heading-1">IDP Info</h1>
        <pm-idp-info disable-json environment-id="{{environmentId}}" tenant-id="{{tenantId}}"></pm-idp-info>
      `,
    },
    {
      pattern: 'deployments/:environmentId',
      view: ctx => {
        if (ctx.params.environmentId === 'prod') {
          return html`<h1>Deployments for Production are not available</h1>`;
        }
        return html`<pm-deployments environment-id="${ctx.params.environmentId}"></pm-deployments>`;
      },
    },
    {
      pattern: 'tenants/:environmentId/:tenantId',
      view: ctx => {
        if (ctx.params.environmentId === 'prod') {
          return html`<h1>Production tenants are not available</h1>`;
        }
        return ctx.search.fuss === 'no' ?
          html`<pm-tenant relay-state="${ctx.search.relayState || ''}" environment-id="${ctx.params.environmentId}" tenant-id="${ctx.params.tenantId}"></pm-tenant>`
          : html`<pm-fuss-tenant relay-state="${ctx.search.relayState || ''}" environment-id="${ctx.params.environmentId}" tenant-id="${ctx.params.tenantId}"></pm-fuss-tenant>`;
      },
    },
    {
      pattern: '/',
      view: () => html`<pm-environments></pm-environments>`,
    },
    {
      pattern: 'stage/:environmentId',
      view: ctx => {
        if (ctx.params.environmentId === 'prod') {
          return html`<h1>Production environment is not available</h1>`;
        }
        return html`<pm-stage environment-id="${ctx.params.environmentId}"></pm-stage>`;
      },
    },
    {
      pattern: 'home',
      view: () => html`<pm-home></pm-home>`,
    },
  ],
  { basePath: '/' }
);
